import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Connects to data-controller="choices"
export default class extends Controller {
  connect() {
    if (this.element.classList.contains("choices__input")) {
      return;
    }

    const choice = new Choices(this.element, {
      noResultsText: "No hay resultados",
      noChoicesText: "No hay opciones para elegir",
      itemSelectText: "",
      removeItemButton: true,
      removeItemButtonAlignLeft: false,
      removeItems: true,
      shouldSort: false,
      shouldSortItems: false,
      loadingText: "Cargando...",
    });

    this.element.addEventListener("choices:change", (event) => {
      const target = event.target;
      const value = target.value;
      choice.setChoiceByValue(value);
    });

    this.element.addEventListener("choices:load", (event) => {
      console.log("choices:load");
      console.log(event.target);
      choice.setChoices(event.detail.choices);
    });

    this.element.addEventListener("choices:clean", () => {
      choice.clearStore();
    });
  }
}

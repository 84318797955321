import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

// Este controlador se usa especificamente para cálcular la reducción anual
// de una iniciativa (Iniciative, IniciativeScenario)
export default class extends Controller {
  static targets = ["output"];

  connect() {
    this.annualReductionInput = document.getElementById(
      "initiative_scenarios_attributes_1_annual_reduction",
    );

    this.operatingCostBaseInput = document.getElementById(
      "initiative_scenarios_attributes_0_operating_cost",
    );

    this.operatingCostProjectInput = document.getElementById(
      "initiative_scenarios_attributes_1_operating_cost",
    );

    this.annualizedCostBaseInput = document.getElementById(
      "initiative_scenarios_attributes_0_annualized_cost",
    );

    this.annualizedCostProjectInput = document.getElementById(
      "initiative_scenarios_attributes_1_annualized_cost",
    );

    this.operatingCostBaseInput.addEventListener("input", () => {
      this.calculate();
    });

    this.operatingCostProjectInput.addEventListener("input", () => {
      this.calculate();
    });

    this.annualizedCostBaseInput.addEventListener("input", () => {
      this.calculate();
    });

    this.annualizedCostProjectInput.addEventListener("input", () => {
      this.calculate();
    });
  }

  calculate() {
    const anualizedCostBase = parseFloat(
      this.annualizedCostBaseInput.value || 0,
    );
    const anualizedCostProject = parseFloat(
      this.annualizedCostProjectInput.value || 0,
    );
    const operatingCostBase = parseFloat(
      this.operatingCostBaseInput.value || 0,
    );
    const operatingCostProject = parseFloat(
      this.operatingCostProjectInput.value || 0,
    );
    const annualReduction = parseFloat(this.annualReductionInput.value || 0);

    console.log(
      JSON.stringify(
        {
          anualizedCostBase,
          anualizedCostProject,
          operatingCostBase,
          operatingCostProject,
          annualReduction,
        },
        null,
        2,
      ),
    );
    const base = operatingCostBase + anualizedCostBase;
    const project = operatingCostProject + anualizedCostProject;
    const result = (project - base) / annualReduction;

    this.outputTarget.value = result.toFixed(3);
  }
}

import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import debounce from "lodash.debounce";

// Connects to data-controller="live-input"
export default class extends Controller {
  static targets = ["input", "output"];
  static values = {
    debounce: { type: Number, default: 400 },
    url: { type: String, default: "" },
  };

  connect() {
    this.submit = debounce(this.submit, this.debounceValue).bind(this);
  }

  async submit() {
    if (this.urlValue == "") {
      console.error("[live-input] url is required");
      return;
    }

    const query = new URLSearchParams([]);

    this.inputTargets.forEach((target) => {
      query.set(target.dataset.liveInputKey, target.value);
    });

    this.outputTargets.forEach((target) => {
      target.closest(".control").classList.add("is-loading");
    });

    try {
      const response = await get(this.urlValue, {
        responseKind: "json",
        query,
      });
      const { result = 0 } = await response.json;

      this.outputTargets.forEach((target) => {
        const controlEl = target.closest(".control");
        if (controlEl) {
          controlEl.classList.remove("is-loading");
        }

        target.value = result;
        target.dispatchEvent(new Event("input"));
      });
    } catch (error) {
      this.outputTargets.forEach((target) => {
        const controlEl = target.closest(".control");
        if (controlEl) {
          controlEl.classList.remove("is-loading");
        }
      });
    }
  }
}

import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="turbo-select"
export default class extends Controller {
  static values = {
    recipient: { type: String, default: "" },
    path: { type: String, default: "" },
    queryKey: { type: String, default: "" },
  };
  async submit(event) {
    const query = new URLSearchParams([
      [this.queryKeyValue, event.target.value],
    ]);
    const response = await get(this.pathValue, {
      responseKind: "json",
      query,
    });
    const json = await response.json;
    const newChoices = json.map((choice) => {
      return {
        value: choice.id,
        label: choice.name,
      };
    });

    const recipientEl = document.querySelector(`#${this.recipientValue}`);

    const choiceEvent = new CustomEvent("choices:load", {
      detail: { choices: newChoices },
    });

    recipientEl.dispatchEvent(new CustomEvent("choices:clean"));
    recipientEl.dispatchEvent(choiceEvent);
  }
}

import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

// Este controlador se usa especificamente para cálcular la reducción anual
// de una iniciativa (Iniciative, IniciativeScenario)
export default class extends Controller {
  static targets = ["output", "label"];

  connect() {
    this.calculate = debounce(this.calculate, this.waitValue).bind(this);
    this.annualReductionInput = document.getElementById(
      this.element.dataset.abatementPotentialAnnualReductionInput,
    );
    this.startDateInput = document.getElementById(
      this.element.dataset.abatementPotentialStartDateInput,
    );

    this.finishDateInput = document.getElementById(
      this.element.dataset.abatementPotentialFinishDateInput,
    );

    this.annualReductionInput.addEventListener("input", () => {
      this.calculate();
    });
    this.startDateInput.addEventListener("input", () => {
      this.calculate();
    });
    this.finishDateInput.addEventListener("input", () => {
      this.calculate();
    });

    const startYear = new Date(
      this.startDateInput.value || new Date(),
    ).getFullYear();
    const finishYear = new Date(
      this.finishDateInput.value || new Date(),
    ).getFullYear();
    const years = finishYear - startYear;
    this.labelTarget.innerText = `tCO2e en ${years} años`;
    console.log(this.labelTarget);
  }

  calculate() {
    const startYear = new Date(
      this.startDateInput.value || new Date(),
    ).getFullYear();
    const finishYear = new Date(
      this.finishDateInput.value || new Date(),
    ).getFullYear();
    const years = finishYear - startYear;
    this.labelTarget.innerText = `tCO2e en ${years} años`;
    const annualReduction = parseFloat(this.annualReductionInput.value || 0);
    this.outputTarget.value = (annualReduction * years).toFixed(2);
  }
}

import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

// Este controlador se usa especificamente para cálcular el costo anualizado
// de una iniciativa (Iniciative, IniciativeScenario)
export default class extends Controller {
  static targets = ["investmentCost", "operatingCost", "output"];

  static values = {
    total: { type: Number, default: 0 },
  };
  connect() {
    this.calculate = debounce(this.calculate, this.waitValue).bind(this);
    this.startDateInput = document.getElementById(
      this.element.dataset.annualizedCostStartDateInput,
    );
    this.finishDateInput = document.getElementById(
      this.element.dataset.annualizedCostFinishDateInput,
    );

    this.discountRateInput = document.getElementById(
      this.element.dataset.annualizedCostDiscountRateInput,
    );

    this.discountRateInput.addEventListener("input", () => {
      this.calculate();
    });
    this.startDateInput.addEventListener("input", () => {
      this.calculate();
    });
    this.finishDateInput.addEventListener("input", () => {
      this.calculate();
    });
    this.investmentCostTarget.addEventListener("input", () => {
      this.calculate();
    });

    this.operatingCostTarget.addEventListener("input", () => {
      this.calculate();
    });
  }

  calculate() {
    const startDate = new Date(this.startDateInput.value || new Date());
    const finishDate = new Date(this.finishDateInput.value || new Date());
    const discountRate = parseFloat(this.discountRateInput.value || 0) / 100;
    const investmentCost = parseFloat(this.investmentCostTarget.value || 0);
    const operatingCost = parseFloat(this.operatingCostTarget.value || 0);
    const years = finishDate.getFullYear() - startDate.getFullYear();

    const result =
      (investmentCost * discountRate) /
      (1 - Math.pow(1 + discountRate, -years));
    this.outputTarget.value = (result + operatingCost).toFixed(2);
    this.outputTarget.dispatchEvent(new Event("input"));
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="select-action"
export default class extends Controller {
  static targets = ["prev", "next"];
  static values = {
    elId: String,
  };

  connect() {}

  elIdValueChanged() {
    if (!this.elIdValue) return;

    this.update();

    const select = document.getElementById(this.elIdValue);
    select.addEventListener("change", () => this.update());
  }

  update() {
    const select = document.getElementById(this.elIdValue);
    const INITIAL_INDEX = 1;

    if (select.selectedIndex === INITIAL_INDEX) {
      this.prevTarget.disabled = true;
    } else {
      this.prevTarget.disabled = false;
    }

    if (select.selectedIndex === select.options.length - 1) {
      this.nextTarget.disabled = true;
    } else {
      this.nextTarget.disabled = false;
    }
  }

  next() {
    this.navigate("next");
  }

  prev() {
    this.navigate("prev");
  }

  navigate(direction) {
    const select = document.getElementById(this.elIdValue);
    const options = select.options;

    if (direction === "prev") {
      console.log({selectedIndex})
      if (selectedIndex > INITIAL_INDEX) {
        this.prevTarget.disabled = false;
        select.selectedIndex = selectedIndex - 1;
      }
    } else if (direction === "next") {
      if (selectedIndex !== options.length - 1) {
        select.selectedIndex = selectedIndex + 1;
      }
    }

    const newValue = select.options[select.selectedIndex].value;
    select.value = newValue;
    this.update();
    if (select.dataset.choice === "active") {
      select.dispatchEvent(new CustomEvent("choices:change"));
    }
    select.dispatchEvent(new Event("change"));
  }

  getOptions() {
    const el = document.getElementById(this.elIdValue);
    return Array.from(el.options).filter((option) => option.value !== "");
  }
}

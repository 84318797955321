import { Controller } from "@hotwired/stimulus";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

// Connects to data-controller="maccs-chart"
export default class extends Controller {
  static values = {
    items: Array,
  };
  connect() {
    Chart.register(annotationPlugin);
    console.log("[maccs-chart]");
    const canvas = document.createElement("canvas");
    canvas.id = "maccs";
    canvas.style.width = "-webkit-fill-available !important";
    this.element.appendChild(canvas);
    new Chart(document.getElementById("maccs"), {
      options: {
        scales: {
          x: {
            type: "linear",
          },
          y: {
            type: "linear",
          },
        },
        plugins: {
          legend: {},
          annotation: {
            annotations: this.itemsValue.reduce(
              ([lastXMax, result], xy, idx, values) => {
                // Construct the box
                const xMin = lastXMax;
                const xMax = xMin + xy[0];
                const yMin = xy[1] < 0 ? xy[1] : 0;
                const yMax = xy[1] > 0 ? xy[1] : 0;
                result[`box${idx}`] = {
                  type: "box",
                  xMin,
                  xMax,
                  yMin,
                  yMax,
                  backgroundColor: xy[2],
                  borderColor: xy[2],
                };
                return [xMax, result];
              },
              [0, {}],
            )[1],
          },
        },
      },
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

// Connects to data-controller="cascading-choices"
export default class extends Controller {
  static targets = ["choice"];

  async get(event) {
    const el = event.target;
    const url = el.dataset.url;

    const currentIndex = this.choiceTargets.indexOf(el);
    const targetChoice = this.choiceTargets[currentIndex + 1];

    if (!el.value && currentIndex === 0) {
      this.choiceTargets.slice(currentIndex + 1).forEach((choice) => {
        choice.dispatchEvent(new CustomEvent("choices:clean"));
      });
      return;
    }

    if (targetChoice && !el.value) {
      targetChoice.dispatchEvent(new CustomEvent("choices:clean"));
      return;
    }

    if (targetChoice) {
      targetChoice.dispatchEvent(new CustomEvent("choices:clean"));
    }

    const query = new URLSearchParams([[el.dataset.queryKey, el.value]]);
    const choiceEvent = new CustomEvent("choices:load", {
      detail: {
        choices: async () => {
          try {
            const response = await get(url, {
              responseKind: "json",
              query,
            });
            const options = await response.json;
            return options;
          } catch (err) {
            console.error(err);
          }
        },
      },
    });

    if (targetChoice) {
      targetChoice.dispatchEvent(choiceEvent);
    }
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="active-link"
export default class extends Controller {
  static targets = ["link"];
  static values = {
    path: { default: "", type: String },
  };
  static classes = ["css"];

  connect() {}

  linkTargetConnected(target) {
     if (target.href == this.pathValue) {
      this.linkTargets.forEach((linkTarget) => {
        linkTarget.classList.remove(this.cssClass);
      });

      target.classList.add(this.cssClass);

    }
  }

  setActivePath(event) {
    const el = event.target;
    this.pathValue = el.href;
  }

  pathValueChanged(current, _old) {
    this.linkTargets.forEach((linkTarget) => {
      if (linkTarget.href === current) {
        linkTarget.classList.add(this.cssClass);
      } else {
        linkTarget.classList.remove(this.cssClass);
      }
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";

// Este controlador se usa especificamente para cálcular la reducción anual
// de una iniciativa (Iniciative, IniciativeScenario)
export default class extends Controller {
  static targets = ["output"];

  connect() {
    this.calculate = debounce(this.calculate, this.waitValue).bind(this);
    this.totalEmissionBaseInput = document.getElementById(
      this.element.dataset.annualReductionTotalEmissionBaseInput,
    );
    this.totalEmissionProjectInput = document.getElementById(
      this.element.dataset.annualReductionTotalEmissionProjectInput,
    );

    this.totalEmissionBaseInput.addEventListener("input", () => {
      this.calculate();
    });

    this.totalEmissionProjectInput.addEventListener("input", () => {
      this.calculate();
    });
  }

  calculate() {
    const base = parseFloat(this.totalEmissionBaseInput.value || 0);
    const project = parseFloat(this.totalEmissionProjectInput.value || 0);
    const reduction = (base - project).toFixed(2);
    this.outputTarget.value = reduction;
    this.outputTarget.dispatchEvent(new Event("input"));
  }
}
